import request from "@/assets/api/request";
export default (url, data, method, sfun, params) => {
  request({
    url,
    data,
    params,
    method,
  }).then((res) => {
    sfun(res);
  });
};
