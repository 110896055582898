<template>
  <div class="iframe">
    <iframe
      :src="iframeSrc"
      style="width: 100%; height: 100%"
      class="iframe"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      iframeSrc: "",
    };
  },
  methods: {},
  mounted() {
    let url = this.$route.query.iframeUrl;
    this.iframeSrc = url + "?id=" + localStorage.getItem("accountId");
  },
};
</script>

<style lang="less" scoped>
.iframe {
  width: 100%;
  height: 100%;
  border: 0;
  background-color: transparent;
}
</style>