<template>
  <div class="">11</div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    let url = window.location.href;
    let p = url.split("?")[1];
    if (!p) {
      this.$router.push({
        name: "main.home",
      });
    }
    let params = new URLSearchParams(p);
    let jump = params.get("jumpflag").split("#")[0];
    if (jump) {
      sessionStorage.setItem("navPop", true);
      this.$router.push({
        name: "main.mine",
      });
    } else {
      sessionStorage.setItem("navPop", false);
      this.$router.push({
        name: "main.home",
      });
    }
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style lang="scss" scoped></style>
