<template>
  <div class="linePage">
    <div class="box">
      <div class="itemBox" @click="goDetails()">
        <img src="../../assets/images/1.jpg" alt="" />
        <div class="info">
          <h2>如心打卡路线1</h2>
          <p>
            <img src="../../assets/images/jinbi.png" alt="" />
            <span>可获得 <em>25</em> 个金币</span>
          </p>
        </div>
        <div class="btn">
          <img src="../../assets/images/paobu.png" alt="" />
          <span>进入</span>
        </div>
      </div>
      <div class="tips">完成2次，共获得50个积分</div>
    </div>
    <div class="box">
      <div class="itemBox">
        <img src="../../assets/images/1.jpg" alt="" />
        <div class="info">
          <h2>如心打卡路线1</h2>
          <p>
            <img src="../../assets/images/jinbi.png" alt="" />
            <span>可获得 <em>25</em> 个金币</span>
          </p>
        </div>
        <div class="btn">
          <img src="../../assets/images/paobu.png" alt="" />
          <span>进入</span>
        </div>
      </div>
      <div class="tips">完成2次，共获得50个积分</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      code: "",
      result: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    goDetails() {
      this.$router.push({
        name: "main.lineDetails",
      });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.linePage {
  width: 100%;
  height: 100%;
  padding: 15px;
  .box {
    margin-bottom: 15px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    .tips {
      background-color: #fff9ed;
      font-size: 12px;
      color: #ff8e08;
      padding: 10px;
    }
    .itemBox {
      padding: 10px;
      display: flex;
      align-items: center;
      img {
        width: 70px;
        height: 60px;
        display: block;
        margin-right: 10px;
        border-radius: 6px;
      }
      .info {
        flex: 1;
        h2 {
          font-size: 17px;
          margin-top: 5px;
        }
        p {
          display: flex;
          align-items: center;
          font-size: 12px;
          margin-top: 5px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 3px;
          }
          span {
            em {
              color: #ff8e08;
              font-style: normal;
            }
          }
        }
      }
      .btn {
        width: 70px;
        height: 23px;
        border-radius: 20px;
        background: linear-gradient(to right, #ff8e08, #ffc25a);
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 13px;
          height: auto;
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
