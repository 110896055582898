<template>
    <div class="home">
        <div class="homeContent">
            <div class="mineBox">
                <div class="headBox">
                    <img :src="headUrl ? headUrl : userImg" alt />
                    <div class="info">
                        <p class="tips">
                            <span>温馨提示</span>
                            <img src="../../assets/images/tips.png" alt />
                        </p>
                        <p class="says">此处认证与智慧跑道的识别关联</p>
                    </div>
                </div>
                <div>
                    <div class="contain">
                        <div class="box1">
                            <img src="../../assets/images/face.png" alt />
                            <span>请上传自己的正脸照片</span>
                        </div>
                        <div class="box2">
                            <span>有效避免</span>
                            <p>识别不准确及运动数据无法同步</p>
                        </div>
                    </div>
                    <van-uploader :after-read="afterRead">
                        <div class="btn">上传认证</div>
                        <!-- <div class="btn" @click="uploadImg">上传认证</div> -->
                    </van-uploader>
                </div>
            </div>
            <div class="infoBox">
                <div class="leftBox">
                    <div class="content">
                        <div class="title">智慧跑道</div>
                        <img :src="pd.coverImg" alt class="img" />
                        <div class="btn" @click="showBigImg = true">查看实景大图</div>
                        <p>{{ pd.describeText }}</p>
                      <!--   <div class="p" @click="goMap()">
                            <img src="../../assets/images/run.png" alt />
                            <span>去这里</span>
                        </div> -->
                    </div>
                </div>
                <div class="rightBox">
                    <div class="box">
                        <h3>
                            天气预报
                            <span>{{ today }}</span>
                        </h3>
                        <img src="../../assets/images/weather.png" class="img" alt />
                        <div class="weather">
                            <span>{{ weather.temperature }}℃</span>
                            <em>{{ weather.weather }}</em>
                        </div>
                    </div>
                    <div class="box">
                        <h3>信息公示</h3>
                        <van-swipe style="height: 160px; margin-top: 10px">
                            <van-swipe-item v-for="item in gsImg" :key="item.informationPublicityId" @click="getText(item)">
                                <img :src="item.coverImage" alt />
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </div>
            </div>
            <!-- <div class="bx_txt" @click="goPage('main.repair')">故障报修</div> -->
        </div>
        <div class="tarbarBox">
            <div class="item" @click="goPage('main.home')">
                <img src="../../assets/images/icon3.png" alt />
                <p class="on">首页</p>
            </div>
            <div class="item" @click="goPage('main.mine')">
                <img src="../../assets/images/icon4.png" alt />
                <p>个人数据</p>
            </div>
        </div>
        <van-image-preview v-model="showBigImg" :images="images"></van-image-preview>
        <van-image-preview v-model="showInfoImg" :images="infoImg"></van-image-preview>
    </div>
</template>
<script>
import userImg from "../../assets/images/user.png";
export default {
    components: {},
    data() {
        return {
            userImg,
            headUrl: "", //认证的图片地址
            pd: {},
            showBigImg: false,
            images: [], //实景大图
            weather: {},
            today: "",
            gsImg: [],
            showInfoImg: false,
            infoImg: [],
            imgUrl: "",
            navPop: true,
        };
    },
    computed: {},
    watch: {},
    // beforeRouteLeave(to, from, next) {
    //     if (!this.navPop) {
    //         next();
    //     } else {
    //         window.$jssdk("nav.pop");
    //     }
    // },
    methods: {
        goPage(page) {
            this.navPop = false;
            this.$router.push({
                name: page,
            });
        },
        dataURLtoFile(dataurl, filename = "file") {
            let arr = dataurl.split(",");
            let mime = arr[0].match(/:(.*?);/)[1];
            let suffix = mime.split("/")[1];
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], `${filename}.${suffix}`, {
                type: mime,
            });
        },
        afterRead(e) {
            let formFile = new FormData();
            formFile.append("file", e.file);
            this.getAjax(
                "api/cool/public/upload/raxw",
                formFile,
                "post",
                (res) => {
                    this.getAjax('api/rapathlife/login/face', {
                        "faceImg": res.data.downloadUrl,
                        "raUserAccountId": localStorage.getItem("accountId")
                    }, 'post', res2 => {
                        if(res2.success){
                            this.$toast('上传头像成功');
                            this.initFace();
                        }else{
                            this.$toast(res2.message);
                        }
                        
                    })
                }, {
                    formData: true
                }
            );
        },
        initFace() {
            this.getAjax(
                "api/rapathlife/login/face", {
                    raUserAccountId: localStorage.getItem("accountId"),
                },
                "get",
                (res) => {
                    this.headUrl = res.data;
                }, {
                    raUserAccountId: localStorage.getItem("accountId"),
                }
            );
        },
        getText(opt) {
            //查询文字
            this.getAjax(
                "api/rapublish/getById", {},
                "get",
                (res) => {
                    let type = res.data.type;
                    if (type === "image") {
                        this.$router.push({
                            path: "/main/infoImg?imgurl=" + res.data.linkImage,
                        });
                    } else if (type === "link") {
                        CP.urlRedirect({
                            url: res.data.linkImage,
                        });
                        // window.$jssdk("nav.link", { url: res.data.linkImage });
                    }
                }, {
                    informationPublicityId: opt.informationPublicityId,
                }
            );
        },
        goMap() {
            //去导航
            window.$jssdk("app.callGaoDeMap", {
                dlat: this.pd.lat,
                dlon: this.pd.lng,
                dname: this.pd.address,
            });
        },
    },

    mounted() {
        //查看是否认证
        this.initFace();

        //查询心智慧报道数据信息公示
        this.getAjax("api/rasmart/info", {}, "get", (res) => {
            this.pd = res.data || {};
            this.images.push(res.data?.realPicture);
        });

        //查询如心信息公示
        this.getAjax(
            "api/rapublish/info", {},
            "get",
            (res) => {
                this.gsImg = res.data;
            }, {}
        );

        //获取天气预报
        this.getAjax(
            "api/weaterNew/zhoushan", {},
            "get",
            (res) => {
                this.weather = res.lives[0];
                this.today = this.dayjs().format("MM-DD");
            }, {}
        );
    },
};
</script>
<style lang="less" scoped>
.home {
    padding: 15px 15px 70px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;

    /deep/ .van-uploader__input {
        opacity: 0 !important;
    }

    img {
        width: 100%;
    }

    .homeContent {
        .bx_txt {
            text-align: center;
            margin-top: 20px;
            color: #999;
            text-decoration: underline;
        }

        .mineBox {
            background: #fff;
            padding: 15px;
            border-radius: 8px;

            .headBox {
                display: flex;

                img {
                    width: 80px;
                    height: 80px;
                    border-radius: 5px;
                    margin-right: 15px;
                }

                .info {
                    flex: 1;

                    .tips {
                        display: flex;
                        align-items: center;
                        margin-top: 18px;

                        span {
                            font-size: 17px;
                        }

                        img {
                            width: 15px;
                            height: 15px;
                            margin-left: 3px;
                        }
                    }

                    .says {
                        font-size: 13px;
                        color: #666;
                        margin-top: 5px;
                    }
                }
            }

            .contain {
                background: #fff9ed;
                border-radius: 5px;
                margin-top: 15px;

                .box1 {
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 17px;
                        height: 17px;
                        margin-right: 5px;
                    }

                    span {
                        font-size: 12px;
                        color: #ff910d;
                    }
                }

                .box2 {
                    padding: 15px;
                    border-top: 1px solid #ffca89;
                    position: relative;

                    span {
                        width: 64px;
                        height: 18px;
                        background: #484848;
                        color: #fff;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        position: absolute;
                        margin-top: -24px;
                        left: 50%;
                        margin-left: -32px;
                        border-radius: 10px;
                    }

                    p {
                        text-align: center;
                        font-size: 12px;
                        transform: scale(0.9);
                    }
                }
            }

            .btn {
                width: calc(100vw - 70px);
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 13px;
                color: #fff;
                background: linear-gradient(to right, #ff8e08, #ffc25a);
                margin-top: 15px;
                border-radius: 100px;
                z-index: -1;
            }
        }

        .infoBox {
            display: flex;
            margin-top: 15px;

            .leftBox {
                flex: 1;
                background: #fff;
                border-radius: 8px;
                box-sizing: border-box;
                margin-right: 15px;

                .content {
                    padding: 15px;
                }

                .title {
                    font-size: 17px;
                    color: #333;
                }

                .img {
                    width: 100%;
                    height: 117px;
                    display: block;
                    margin-top: 10px;
                    border-radius: 8px;
                }

                .btn {
                    width: 100%;
                    height: 32px;
                    line-height: 32px;
                    border: 1px solid #ff910d;
                    border-radius: 100px;
                    color: #ff910d;
                    text-align: center;
                    font-size: 13px;
                    margin-top: 10px;
                }

                p {
                    color: #666;
                    font-size: 13px;
                    margin-top: 8px;
                    line-height: 1.8;
                }

                .p {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 5px;
                    }

                    span {
                        font-size: 16px;
                    }
                }
            }

            .rightBox {
                flex: 1;
                width: 0;

                .box {
                    flex: 1;
                    padding: 15px;
                    background: #fff;
                    border-radius: 8px;
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    h3 {
                        font-size: 17px;
                        display: flex;
                        align-items: center;
                        font-weight: normal;

                        span {
                            font-size: 12px;
                            color: #666;
                            flex: 1;
                            text-align: right;
                        }
                    }

                    .img {
                        width: 75px;
                        height: auto;
                        display: block;
                        margin: 10px auto;
                    }

                    .weather {
                        display: flex;
                        align-items: center;
                        margin-top: 10px;

                        span {
                            flex: 1;
                            text-align: center;
                        }

                        em {
                            flex: 1;
                            text-align: center;
                            font-style: normal;
                        }
                    }

                    .banner {
                        width: 100%;
                        height: 118px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}
</style>