<template>
  <div class="infoImg">
    <img :src="imgurl" alt="">
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      imgurl:''
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {
      this.imgurl = this.$route.query.imgurl
  },
};
</script>

<style lang="less">
.infoImg {
  width: 100%;
  height: 100%;
  img{
      display: block;
      width: 100%;
      height: auto;
  }
}
</style>
