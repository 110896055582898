import Vue from "vue";
import VueRouter from "vue-router";
import main from "../views/index.vue";
import home from "../views/home/home";
import mine from "../views/home/mine";
import myData from "../views/home/myData";
import line from "../views/home/line";
import lineDetails from "../views/home/lineDetails";
import infoImg from "../views/home/infoImg";
import repair from "../views/home/repair";
import iframe from "../views/home/iframe";
import redirect from "../views/home/redirect";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    redirect: "/main/home",
    component: main,
    children: [
      {
        path: "/main/redirect",
        name: "main.redirect",
        component: redirect,
        meta: {
          name: "中转页面",
        },
      },
      {
        path: "/main/home",
        name: "main.home",
        component: home,
        meta: {
          name: "首页",
        },
      },
      {
        path: "/main/mine",
        name: "main.mine",
        component: mine,
        meta: {
          name: "我的",
        },
      },
      {
        path: "/main/myData",
        name: "main.myData",
        component: myData,
        meta: {
          name: "个人数据",
        },
      },
      {
        path: "/main/lineDetails",
        name: "main.lineDetails",
        component: lineDetails,
        meta: {
          name: "路线详情",
        },
      },
      {
        path: "/main/line",
        name: "main.line",
        component: line,
        meta: {
          name: "路线详情",
        },
      },
      {
        path: "/main/infoImg",
        name: "main.infoImg",
        component: infoImg,
        meta: {
          name: "信息详情",
        },
      },
      {
        path: "/main/repair",
        name: "main.repair",
        component: repair,
        meta: {
          name: "故障报修",
        },
      },
      {
        path: "/main/iframe",
        name: "main.iframe",
        component: iframe,
        meta: {
          name: "外部链接",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
