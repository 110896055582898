<template>
  <div class="lineDetails">
    <div class="box">
      <img :src="info.imgUrl" alt="" />
      <h2>{{info.name}}</h2>
      <p>共完成打卡 <span>{{info.quanshu}}</span> 次</p>
      <p>本圈已走<span>{{info.step}}</span>步/完成打卡一次共需<span>{{info.allStep}}</span>步</p>
      <!-- <p>
        <img src="../../assets/images/jinbi.png" alt="" />
        每人每天最高可以获得 <span>15</span> 积分~
      </p> -->
    </div>
    <div class="btnBox" @click="goIframe()">
      <img src="../../assets/images/paobu.png" alt="" />
      <span>进入路线 全程{{info.allStep}}步</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      info:{},
      index:0,
      lineList:[
        {
          imgUrl:'https://screen.sunmer.cn/ruxin/rxone3.jpg',
          name:'如心十景环岛游',
          quanshu:0,
          step:0,
          allStep:12600,
          iframeUrl:'https://screen.sunmer.cn/ruxin/clock/sjclock.html'
        },
        {
          imgUrl:'https://screen.sunmer.cn/ruxin/rxtwo3.png',
          name:'如心绿道',
          quanshu:0,
          step:0,
          allStep:7400,
          iframeUrl:'https://screen.sunmer.cn/ruxin/clock/index2.html'
        },
        {
          imgUrl:'https://screen.sunmer.cn/ruxin/rxthree3.png',
          name:'如心邻里运动中心智慧跑道',
          quanshu:0,
          step:0,
          allStep:310,
          iframeUrl:'https://screen.sunmer.cn/ruxin/clock/index3.html'
        }
      ]
    };
  },
  computed: {},
  watch: {},
  methods: {
    goIframe(){
      this.$router.push({
        name: 'main.iframe',
        query:{
          iframeUrl:this.info.iframeUrl
        }
      });
    }
  },
  created() {},
  mounted() {
    this.getAjax(
      "api/gtdream/clock/getById?openId="+localStorage.getItem("accountId"),
      {},
      "get",
      (res) => {
        this.lineList[0].quanshu = res.data.mapRuxinOneCount;
        this.lineList[1].quanshu = res.data.mapRuxinTwoCount;
        this.lineList[2].quanshu = res.data.mapRuxinThreeCount;
        this.lineList[0].step = res.data.mapRuxinOneSteps;
        this.lineList[1].step = res.data.mapRuxinTwoSteps;
        this.lineList[2].step = res.data.mapRuxinThreeSteps;
        this.index = this.$route.query.opt;
        this.info = this.lineList[this.index];
      },{}
    );
    
    
  },
};
</script>

<style lang="less" scoped>
.lineDetails {
  width: 100%;
  height: 100%;
  padding: 15px;
  .box {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    h2 {
      text-align: center;
      font-size: 18px;
      margin-top: 15px;
    }
    p {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: center;
      img {
        width: 14px;
        height: 14px;
      }
      span {
        color: #ff8e08;
      }
    }
  }
  .btnBox {
    height: 44px;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #ff8e08, #ffc25a);
    font-size: 18px;
    color: #fff;
    border-radius: 100px;
    margin-top: 15px;
    justify-content: center;
    img {
      width: 18px;
      height: 20px;
      margin-right: 5px;
    }
  }
}
</style>
