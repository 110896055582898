<template>
  <div class="myDataBox">
    <div class="searchBox">
      <div class="box" @click="showStartTime = true">
        <img
          src="../../assets/images/calendar.png"
          mode="widthFix"
          style="width: 12px"
        />
        <span>{{ startTime ? startTime : "开始日期" }}</span>
      </div>
      <div class="line">-</div>
      <div class="box" @click="showEndTime = true">
        <img
          src="../../assets/images/calendar.png"
          mode="widthFix"
          style="width: 12px"
        />
        <span>{{ endTime ? endTime : "结束日期" }}</span>
      </div>
      <div class="btn" @click="searchData()">
        <img
          src="../../assets/images/search.png"
          mode="widthFix"
          style="width: 12px"
        />搜索
      </div>
    </div>
    <div class="today">
      <span>今日数据</span>
      <div class="box">
        <div
          class="num"
          v-if="todayRank == -1"
          style="font-size: 15px; margin-bottom: 5px"
        >
          今日无排名
        </div>
        <div class="num" v-else>{{ todayRank }}</div>
        <div class="value">当日排名</div>
      </div>
      <div class="box">
        <div class="num">{{ cumulativeRank }}</div>
        <div class="value">累计总排名</div>
      </div>
    </div>
    <div class="itemBox" v-for="item in sportHistoryVO" :key="item.createTime">
      <div class="titleBox">
        <div class="date">{{ item.createTime }}</div>
        <div class="rankBox">
          当日排名
          <span>{{ item.rank }}</span>
          <van-icon name="arrow-up" size="16px" />
        </div>
      </div>
      <div class="content">
        <div class="box">
          <div class="name">消耗</div>
          <div class="value">
            <span>{{ item.caloric }}</span
            >Kcal
          </div>
        </div>
        <div class="box">
          <div class="name">运动时长</div>
          <div class="value">
            <span>{{ item.exerciseDuration }}</span
            >min
          </div>
        </div>
        <div class="box">
          <div class="name">运动步数</div>
          <div class="value">
            <span>{{ item.movementSteps }}</span
            >步
          </div>
        </div>
        <div class="box">
          <div class="name">运动距离</div>
          <div class="value">
            <span>{{ item.movingDistance }}</span
            >Km
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showStartTime" position="bottom">
      <van-datetime-picker
        @confirm="insureStartTime"
        @cancel="showStartTime = false"
        v-model="value2"
        type="date"
        title="开始日期"
      />
    </van-popup>
    <van-popup v-model="showEndTime" position="bottom">
      <van-datetime-picker
        @confirm="insureEndTime"
        @cancel="showEndTime = false"
        v-model="value2"
        type="date"
        title="结束日期"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showStartTime: false,
      showEndTime: false,
      startTime: "",
      endTime: "",
      value2: new Date(),
      cumulativeRank: 0,
      todayRank: 0,
      sportHistoryVO: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    insureStartTime(time) {
      this.startTime = this.dayjs(time).format("YYYY-MM-DD");
      this.showStartTime = false;
    },
    insureEndTime(time) {
      this.endTime = this.dayjs(time).format("YYYY-MM-DD");
      this.showEndTime = false;
    },
    searchData() {
      this.initData();
    },
    initData() {
      this.getAjax(
        "api/rapath/data/history",
        {},
        "get",
        (res) => {
          this.todayRank = res.data.todayRank;
          this.cumulativeRank = res.data.cumulativeRank;
          this.sportHistoryVO = res.data.sportHistoryVO;
        },
        {
          start: this.startTime,
          stop: this.endTime,
        }
      );
    },
  },
  created() {
    this.endTime = this.dayjs().format("YYYY-MM-DD");
    this.startTime = this.dayjs().subtract(1, "month").format("YYYY-MM-DD");
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="less" socped>
.myDataBox {
  padding: 15px;
  .searchBox {
    display: flex;
    align-items: center;
    .box {
      display: inline-block;
      padding: 0 5px;
      height: 22px;
      line-height: 22px;
      border-radius: 50px;
      border: 1px solid #a2a2a2;
      font-size: 12px;
      display: flex;
      align-items: center;
      span {
        margin-left: 5px;
      }
    }
    .line {
      margin: 0 10px;
      color: #999;
    }
    .btn {
      width: 70px;
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      color: #fff;
      border-radius: 50px;
      background: linear-gradient(to right, #ff8e08, #ffc25a);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
      img {
        margin-right: 5px;
      }
    }
  }
  .today {
    display: flex;
    padding: 15px;
    border-radius: 15px;
    background-color: #fff;
    margin-top: 15px;
    align-items: center;
    span {
      font-size: 17px;
      color: #333;
      flex: 1;
    }
    .box {
      width: 100px;
      text-align: center;
      .num {
        font-size: 20px;
        color: #fd8d0f;
      }
      .value {
        color: #999;
        font-size: 12px;
      }
    }
  }
  .itemBox {
    padding: 15px;
    border-radius: 15px;
    background-color: #fff;
    margin-top: 15px;
    .titleBox {
      display: flex;
      .date {
        flex: 1;
        font-size: 17px;
        color: #333;
      }
      .rankBox {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        span {
          font-size: 20px;
          color: #fd8d0f;
          margin: 0 10px;
        }
      }
    }
    .content {
      display: flex;
      margin-top: 5px;
      .box {
        flex: 1;
        .name {
          font-size: 14px;
          color: #999;
        }
        .value {
          font-size: 12px;
          color: #999;
          margin-top: 5px;
          span {
            font-size: 20px;
            color: #181725;
          }
        }
      }
    }
  }
}
</style>
