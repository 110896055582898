<template>
  <div class="repair">
    <div class="textbox">
      <van-field
        v-model="text"
        rows="4"
        autosize
        border
        label
        type="textarea"
        maxlength="300"
        placeholder="请在此输入故障说明，并留下您的联系方式，我们将在24小时内由专员和您详细对接故障情况，您的意见对我们很重要，非常感谢！"
        show-word-limit
      />
    </div>
    <div class="tips">请在此输入故障说明，并留下您的联系方式，我们将在24小时内由专员和您详细对接故障情况，您的意见对我们很重要，非常感谢！</div>
    <div class="btn" @click="submitData()">提交</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      text: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    submitData(){
        if(!this.text){
            this.$toast("请输入您的问题");
            return false;
        }
        this.$dialog.confirm({
            message:'确定提交吗？',
        }).then(()=>{
            this.getAjax(
            "api/super_admin/repair/newRepair",
            {
              footpathId: '3301140020000123458',
              question: this.text,
            },
            "post",
            (res2) => {
              if (res2.success) {
                this.$toast("提交成功");
              } else {
                this.$toast(res2.message);
              }
            }
          );
        })
    }
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.repair {
    padding: 10px;
    .textbox{
       border:1px solid #eee;
    }
    .tips{
        margin-top:20px;
        color:#999;
        font-size: 12px;
        padding: 0 10px;
        line-height: 1.8;
    }
    .btn{
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        background: linear-gradient(to right, #ff8e08, #ffc25a);
        margin-top: 15px;
        border-radius: 100px;
        z-index: -1;
    }
}
</style>
