<template>
    <div class="sfin" id="sfin">
        <router-view v-if="showPage" />
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            accountId: "",
            result: "",
            showPage: false,
        };
    },
    computed: {},
    watch: {},
    methods: {},
    created() {},
    mounted() {
        setTimeout(() => {
            // this.accountId = '6409a841c790b07ae6407039';
            const { account_id, head_img, mobile, nick_name } = JSON.parse(localStorage.getItem('loginInfo'));
            this.getAjax(
                "api/rapathlife/login/raLogin", {
                    "raUserAccountId": account_id,
                    "userAvatarUrl": head_img,
                    "userNickname": nick_name,
                    "userPhone": mobile
                },
                "post",
                (res) => {
                    localStorage.setItem("userId", res.data.footpathUserId);
                    localStorage.setItem("accountId", res.data.accountId);
                    localStorage.setItem("userInfo", res.data);
                    this.showPage = true;
                }
            );
        }, 500);
    },
};
</script>
<style lang="less">
.sfin {
    width: 100%;
    height: 100%;
}
</style>