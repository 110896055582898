import Vue from 'vue'
import App from './App.vue'
import router from './router'
import getAjax from '@/assets/api/getAjax'
import '@/assets/less/common.less'
import 'vant/lib/index.css'
import * as echarts from "echarts";
import dayjs from "dayjs"

import {
  Field,
  Popup,
  Checkbox,
  CheckboxGroup,
  Toast,
  Button,
  Dialog,
  ImagePreview,
  DatetimePicker,
  Uploader,
  Swipe,
  SwipeItem
} from 'vant'
Vue.use(Field)
Vue.use(Popup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Toast)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(ImagePreview)
Vue.use(DatetimePicker)
Vue.use(Uploader)
Vue.use(Swipe)
Vue.use(SwipeItem)

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts;



// 挂载getAjax
Vue.prototype.getAjax = getAjax
Vue.prototype.dayjs = dayjs;


new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
