<template>
  <div class="sportData">
    <div class="myData">
      <div class="dataBox">
        <div class="title">
          <span>今日数据</span>
          <img src="../../assets/images/data0.png" style="width: 16px" />
        </div>
        <div class="box">
          <img src="../../assets/images/data1.png" />
          <div class="info">
            <div class="name">运动时长</div>
            <div class="value">
              <span>{{ todayVO.exerciseDuration }}</span
              >min
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data2.png" />
          <div class="info">
            <div class="name">运动步数</div>
            <div class="value">
              <span>{{ todayVO.movementSteps }}</span
              >步
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data3.png" />
          <div class="info">
            <div class="name">运动距离</div>
            <div class="value">
              <span>{{ todayVO.movingDistance }}</span
              >Km
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data4.png" />
          <div class="info">
            <div class="name">消耗</div>
            <div class="value">
              <span>{{ todayVO.caloric }}</span
              >Kcal
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data1.png" />
          <div class="info">
            <div class="name">排名</div>
            <div
              class="value"
              v-if="todayVO.rank == -1"
              style="margin-top: 5px"
            >
              无排名
            </div>
            <div class="value" v-else>
              <span>{{ todayVO.rank }}</span
              >名/总{{ todayVO.count }}人
            </div>
          </div>
        </div>
      </div>
      <div class="dataBox dataBox2">
        <div class="title">
          <span>累计数据</span>
          <img src="../../assets/images/data6.png" alt="" style="width: 12px" />
        </div>
        <div class="box">
          <img src="../../assets/images/data7.png" />
          <div class="info">
            <div class="name">运动时长</div>
            <div class="value">
              <span>{{ cumulativeVO.exerciseDuration }}</span
              >min
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data8.png" />
          <div class="info">
            <div class="name">运动步数</div>
            <div class="value">
              <span>{{ cumulativeVO.movementSteps }}</span
              >步
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data9.png" />
          <div class="info">
            <div class="name">运动距离</div>
            <div class="value">
              <span>{{ cumulativeVO.movingDistance }}</span
              >Km
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data10.png" />
          <div class="info">
            <div class="name">消耗</div>
            <div class="value">
              <span>{{ cumulativeVO.caloric }}</span
              >Kcal
            </div>
          </div>
        </div>
        <div class="box">
          <img src="../../assets/images/data11.png" />
          <div class="info">
            <div class="name">排名</div>
            <div
              class="value"
              v-if="cumulativeVO.rank == -1"
              style="margin-top: 5px"
            >
              无排名
            </div>
            <div class="value" v-else>
              <span>{{ cumulativeVO.rank }}</span
              >名/总{{ cumulativeVO.count }}人
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dayData">
      <div class="title">
        <span>每日数据</span>
        <em @click="goPage('main.myData')">历史数据>></em>
      </div>
      <div class="tabBox">
        <div :class="['tab', active == 0 ? 'on' : '']" @click="changeTab(0)">
          时长
        </div>
        <div :class="['tab', active == 1 ? 'on' : '']" @click="changeTab(1)">
          步数
        </div>
        <div :class="['tab', active == 2 ? 'on' : '']" @click="changeTab(2)">
          公里数
        </div>
        <div :class="['tab', active == 3 ? 'on' : '']" @click="changeTab(3)">
          卡路里值
        </div>
      </div>
      <div class="chartBox">
        <div id="lineChart" style="width: 100%; height: 260px"></div>
      </div>
    </div>
    <!-- <div class="dayData" style="margin-top: 15px">
      <div class="title">
        <span>运动打卡路线</span>
        <em @click="seeSays()">使用说明</em>
      </div>
      <div class="lineBox">
        <div
          class="item"
          v-for="(item, index) in lineList"
          :key="item.name"
          @click="goPage('main.lineDetails', index)"
        >
          <img :src="item.imgUrl" alt="" />
          <p style="font-weight: bold; font-size: 15px">{{ item.name }}</p>
          <p>
            共完成打卡 <span>{{ item.quanshu }}</span> 次
          </p>
          <p>
            <span>{{ item.step }}</span> 步/ <span>{{ item.allStep }}</span> 步
          </p>
        </div>
      </div>
    </div> -->
    <div class="tarbarBox">
      <div class="item" @click="goPage('main.home')">
        <img src="../../assets/images/icon1.png" alt="" />
        <p>首页</p>
      </div>
      <div class="item" @click="goPage('main.mine')">
        <img src="../../assets/images/icon2.png" alt="" />
        <p class="on">个人数据</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      active: 0,
      todayVO: {},
      cumulativeVO: {},
      data: {
        exerciseDuration: [],
        movementSteps: [],
        movingDistance: [],
        caloric: [],
        xData: [],
      },
      option: {},
      lineList: [
        {
          imgUrl: "https://screen.sunmer.cn/ruxin/rxone1.jpg",
          name: "如心十景环岛游",
          quanshu: 0,
          step: 0,
          allStep: 12600,
        },
        {
          imgUrl: "https://screen.sunmer.cn/ruxin/rxtwo1.png",
          name: "如心绿道",
          quanshu: 0,
          step: 0,
          allStep: 7400,
        },
        {
          imgUrl: "https://screen.sunmer.cn/ruxin/rxthree1.png",
          name: "如心邻里运动中心智慧跑道",
          quanshu: 0,
          step: 0,
          allStep: 310,
        },
      ],
      navPop2: true,
    };
  },
  watch: {},
  computed: {},
  beforeRouteLeave(to, from, next) {
    let navPop = sessionStorage.getItem("navPop");
    if (navPop && this.navPop2) {
      window.$jssdk("nav.pop");
    } else {
      next();
    }
  },
  methods: {
    changeTab(opt) {
      this.active = opt;
      if (opt == 0) {
        this.option.series[0].data = this.data["exerciseDuration"];
      } else if (opt == 1) {
        this.option.series[0].data = this.data["movementSteps"];
      } else if (opt == 2) {
        this.option.series[0].data = this.data["movingDistance"];
      } else if (opt == 3) {
        this.option.series[0].data = this.data["caloric"];
      }
      this.myChart.setOption(this.option);
    },
    goPage(page, opt) {
      this.navPop2 = false;
      this.$router.push({
        name: page,
        query: {
          opt,
        },
      });
    },
    seeSays() {
      this.$dialog.alert({
        className: "myDialog",
        message: `
          <p style="text-align:left;">1.用户须前往如心邻里运动中心智慧跑道进行运动。</p>
          <p style="text-align:left;">2.完成运动后点击“使用步数”，用户头像会在地图内行走对应的步数，完成地图打卡。如当日未走完该条路线，会保留已走的步数，下次从该点继续打卡前进。</p>
          <p style="text-align:left;">3.步数兑换积分值：</p>
          <p style="text-align:left;">1000步可兑换1积分</p>
          <p style="text-align:left;">2000步可兑换2积分</p>
          <p style="text-align:left;">3000步可兑换3积分</p>
          <p style="text-align:left;">4000步可兑换4积分</p>
          <p style="text-align:left;">5000步可兑换5积分+奖励2积分=7积分</p>
          <p style="text-align:left;">6000步可兑换8积分</p>
          <p style="text-align:left;">7000步可兑换9积分</p>
          <p style="text-align:left;">8000步可兑换10积分</p>
          <p style="text-align:left;">9000步可兑换11积分</p>
          <p style="text-align:left;">10000步要兑换12积分+奖励3积分=15积分</p>
          <p style="text-align:left;">备注：每位用户每天步数打卡最多能获得APP15积分</p>
        `,
      });
    },
  },
  created() {},
  mounted() {
    this.getAjax("api/rapath/data/sportPage", {}, "post", (res) => {
      let { cumulativeVO, data, todayVO } = res.data;
      this.cumulativeVO = cumulativeVO;
      this.todayVO = todayVO;
      data.forEach((e) => {
        this.data.xData.push(e.thisweek);
        this.data.exerciseDuration.push(e.exerciseDuration);
        this.data.movementSteps.push(e.movementSteps);
        this.data.movingDistance.push(e.movingDistance);
        this.data.caloric.push(e.caloric);
      });

      var chartDom = document.getElementById("lineChart");
      this.myChart = this.$echarts.init(chartDom);
      this.option = {
        xAxis: {
          type: "category",
          data: this.data.xData,
        },
        grid: {
          x: 50,
          y: 25,
          x2: 30,
          y2: 35,
        },
        yAxis: {
          type: "value",
        },
        lineStyle: {
          color: "#FF8E08",
        },
        series: [
          {
            data: this.data.exerciseDuration,
            type: "line",
            itemStyle: {
              normal: {
                color: "#FF8E08",
              },
            },
          },
        ],
      };

      this.option && this.myChart.setOption(this.option);
    });

    this.getAjax(
      "api/gtdream/clock/getById?openId=" + localStorage.getItem("accountId"),
      {},
      "get",
      (res) => {
        this.lineList[0].quanshu = res.data.mapRuxinOneCount;
        this.lineList[1].quanshu = res.data.mapRuxinTwoCount;
        this.lineList[2].quanshu = res.data.mapRuxinThreeCount;
        this.lineList[0].step = res.data.mapRuxinOneSteps;
        this.lineList[1].step = res.data.mapRuxinTwoSteps;
        this.lineList[2].step = res.data.mapRuxinThreeSteps;
      },
      {}
    );
  },
};
</script>
<style lang="less" socped>
.myDialog {
  white-space: inherit;
  .van-dialog__content {
    white-space: inherit;
    .van-dialog__message {
      white-space: inherit;
      p {
        line-height: 1.8;
      }
    }
  }
}
.sportData {
  padding: 15px 15px 70px;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  .myData {
    display: flex;
    margin-bottom: 15px;
    .dataBox {
      flex: 1;
      background: linear-gradient(to right, #ff8e08, #ffc25a);
      padding: 15px;
      border-radius: 15px;
      img {
        width: 21px;
      }
      .title {
        display: flex;
        align-items: center;
        span {
          font-size: 17px;
          color: #fff;
          margin-right: 5px;
        }
      }
      .box {
        padding: 15px 0;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ffcc8c;
        &:last-child {
          border-bottom: 0;
        }
        .info {
          flex: 1;
          margin-left: 10px;
          color: #fff9ed;
          .name {
            font-size: 14px;
          }
          .value {
            font-size: 12px;
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
    .dataBox2 {
      background: #fff;
      margin-left: 15px;
      .title {
        span {
          color: #333;
        }
      }
      .box {
        border-bottom: 1px solid #eee;
        .info {
          color: #333;
          .name {
            color: #999;
          }
          .value {
            color: #999;
            span {
              color: #333;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .dayData {
    background-color: #fff;
    border-radius: 15px;
    padding: 15px;
    .lineBox {
      width: 100%;
      overflow-x: auto;
      margin-top: 10px;
      white-space: nowrap;
      .item {
        width: 130px;
        display: inline-block;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 115px;
          display: block;
          border-radius: 8px;
        }
        p {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            color: #ff8e08;
          }
        }
      }
    }
    .title {
      font-size: 17px;
      color: #333;
      font-weight: 500;
      display: flex;
      span {
        flex: 1;
        font-size: 17px;
      }
      em {
        font-style: normal;
        font-size: 17px;
        color: #ff8e08;
      }
    }
    .tabBox {
      display: flex;
      margin: 15px 0;
      justify-content: center;
      .tab {
        display: inline-block;
        padding: 5px 0;
        font-size: 14px;
        color: #666;
        margin-right: 25px;
        border-bottom: 1px solid #fff;
      }
      .on {
        color: #ff8e08;
        border-bottom: 1px solid #ff8e08;
      }
    }
    .chartBox {
      height: 250px;
      position: relative;
    }
    .canvas {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
.tarbarBox {
  height: 53px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #eee;
  .item {
    flex: 1;
    img {
      display: block;
      width: 22px;
      height: 22px;
      margin: 0 auto;
    }
    p {
      text-align: center;
      font-size: 12px;
      color: #a2a2a2;
      margin-top: 2px;
    }
    .on {
      color: #ff8e08;
    }
  }
}
</style>
